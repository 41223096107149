<template>
    <div class="oml-destinationsListing">
        <div class="oml-destsListing-header">
            <b-row class="d-flex align-items-center np-gutters">
                <b-col cols="4">
                    <div class="topfilter mb-0">
                        <div class="ml-2 my-auto title">
                            {{$t('destinations')}}
							<router-link :to="{ name : 'integrations' }"><i class="fa fa-plus-circle" style="color:#377cff;font-size: 17px;margin-left: 7px;"></i></router-link>
                        </div>
                    </div>
                </b-col>
                <b-col cols="8">
                    <div class="oml-destsListing-filter">
                        <div class="form-group" style="width: 130px;">
                            <!-- <multi-select
                                    faIcon="fa-filter"
                                    :options="types"
                                    :label="$t('Type')"
                                    :selected="tableSearch.selectedFilters"
                                    placeholderType="title"
                                    :placeholder="$t('Type')"
                                    @filterChange="getDestinations(true, 'filter')"
                                    multiple
                                    :defaultSelected="optionsSelectFilter.defaultSelected" 
                                    :selectAll="optionsSelectFilter.selectAll"
                                    :caret="true"
                            ></multi-select> -->
                        </div>
                        <div class="form-group mx-sm-2" style="width: 160px;">
                            <!-- <date-range-picker class="oml-desti-dateRangePicker" :locale-data="datepicker.localData"
                        :ranges="rangesfunc(datepicker.ranges)"
                            ref="picker"  :showWeekNumbers="true" customRangeLabel= 'Custom Range' 
							:showDropdowns="true" v-model="dateRange" @update="getDestinations(true, 'filter')">
								<template v-slot:input="picker" style="min-width: 350px;">
									<div :title="dateRange.startDate">
										<i class="fa fa-calendar d-block mr-1"></i>
										<span v-if="dateRange.startDate" class="range-span d-block">{{ $t('Customize') }}</span>
										<span v-else class="label-range">{{ $t('AllTime') }}</span>
										<i class="fa fa-close init-dateRange" @click="InitDateRange" v-if="Object.keys(dateRange).length > 0"></i>
									</div>
								</template>
							</date-range-picker> -->

                        </div>

                        <div class="form-group input-searchZone-md mx-sm-2"> <!--  has-search ml-sm-3  -->
                            <i class="fa fa-search"></i>
                            <input type="text" class="form-control input-searchZone" v-model="tableSearch.searchQuery" @keyup="searchTimeOut"  :placeholder="$t('Search')" />
                        </div>
                        <!--router-link to="integrations"><button><span>+</span> {{ $t('New_Destinations') }}</button></router-link-->

                    </div>
                </b-col>
            </b-row>

        </div>

        <div class="oml-destinationsListing-body mt-4">

			<data-table :myDestinations="items" :columns="gridColumns"></data-table>
			
            <!--b-table
				:items="items"
				:fields="fields"
				select-mode="multi"
				responsive="sm"
				ref="selectableTable"
				v-model="tableValues"
				@row-selected="onRowSelected"
				@head-clicked="clearSelected"
            >
                <template v-slot:head(selected)="data">
                    <b-form-checkbox
                            v-model="allSelected"
                            @change="selectAllRows"
                    >
                    </b-form-checkbox>
                </template>

                <template #cell(selected)="{ rowSelected }">
                    <b-form-checkbox
                            v-model="rowSelected"
                    >
                    </b-form-checkbox>
                </template>

                <template #cell(leads)="data">
                    <div class="text-center fw-700">
                        {{ data.item.leads }}
                    </div>
                </template>

                <template #cell(status)="data">
                    <div v-for="(state, index) in data.item.status" :key="index">
                        <span class="oml-destStatus" :style="{ backgroundColor: state.color }">{{state.status}}</span> 
                    </div>
                </template>

                <template #cell(details)="row">
                    <div class="text-center">
                        <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret class="margin-auto">
                            <template v-slot:button-content>
                                <i class="fa fa-ellipsis-v dropdown-icon"></i>
                            </template>
                            
                                <b-dropdown-item @click="eventCallBack(row.item.plateform,row.item.id)"><i class="fa fa-check-circle pr-1"></i> {{$t('Edit')}} </b-dropdown-item>
                            
                            <b-dropdown-item @click="deleteEvent(row.item.id)"><i class="fa fa-trash pr-1"></i> {{$t('Delete')}}</b-dropdown-item>
                        </b-dropdown>
                    </div>

                </template>
            </b-table-->
			<!--
			<b-modal id="modal-delete-destination" centered hide-footer title="Confirmation"> 
				<div>
					<div class="row">
						<div class="col-12 mb-1">
							<p class="m-0" style=" color: #000; font-size: 14px; ">{{$t('QuestionContinue')}}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<b-button size="sm" variant="success" @click="deleteDestination()">
								<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{$t('YesDeleteIt')}}
							</b-button>
						</div>
					</div>
				</div>
			</b-modal>
			-->

            <div class="oml-destinationsListing-empty" >
                <div v-if="!items.length" class="d-flex align-items-center justify-content-center flex-column">
                    <img src="/img/empty.png" alt="">
                    <h5>{{$t('Nothing_here_yet')}} !</h5>
                    <!-- <p>You can create an email template by clicking on this button</p> -->
                    <router-link to="integrations"><button v-b-modal="'addTemplates'" class="oml-btn"><span>+</span> {{ $t('New_Destinations') }}</button></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import multiSelect from '@/plugins/md/multiselect/select-dropdown.vue';
    //import DateRangePicker from 'vue2-daterange-picker';
	import dataTable from '@/plugins/md/dataTable/datatable-mydestination.vue';
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

    let timeout = null;
    let day = new Date();
    day.setHours(0, 0, 0, 0);

    let yesterday = new Date()
    yesterday.setDate(day.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);

    export default {
        components: {
            /* multiSelect,
            DateRangePicker */
			dataTable
        },
        data () {
            return {
                filters: ['Select option', 'clearOnSelect', 'hideSelected', 'maxHeight'],
                Times	  : [
                    { id: 'day', title: 'This Day' },
                    { id: 'week', title: 'This Week' },
                    { id: 'month', title: 'This Month' },
                ],
                optionsSelectFilter: {
					selectAll	: true,
					defaultSelected: ''
				},
                tableSearch: {
                    searchQuery		: '',
                    selectedFilters	: [],
                    selectedTimes	: [],
                },

                fields: [
                    // {
                    //     key: 'selected',
                    //     sortable: false
                    // },
                    {
                        key: 'destination_name',
                        label: this.$t('Name_of_destination'),
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: this.$t('Status'),
                        sortable: true
                    },
                    // {
                    //     key: 'type',
                    //     label: this.$t('Type'),
                    //     sortable: true
                    // },
                    {
                        key: 'leads',
                        label: this.$t('Leads'),
                        sortable: true
                    },
                    {
                        key: 'updated_at',
                        label: this.$t('Created_at'),
                        sortable: true
                    },
                    {
                        key: 'details',
                        label: this.$t('Details'),
                        sortable: true
                    },
                ],
				gridColumns: [
					{ title: this.$t('Name_of_destination'), column: 'destination_name',enable: true},
					{ title: this.$t('Conditions'), column: 'status',enable: true},
					{ title: this.$t('Leads'), column: 'leads',enable: true},
					{ title: this.$t('Created_at'), column: 'updated_at',enable: true},
					{ title: this.$t('Status'), column: 'active',enable: true},
					{ title: this.$t('Details'), column: 'details',enable: true}
				],
				selectedAction : [],
                dateRange: {},
                items: [],
                types: [],
                idestination: '',
                selected: [],
                checkedItems: [],
                allSelected: false,
                loadingAction: false,
                tableValues: [],
                transranges:{
                        'Today': this.$t('today'),
                        'Yesterday': this.$t('yesterday'),
                        'This month': this.$t('thismonth'),
                        'This year': this.$t('thisyear'),
                        'Last month': this.$t('lastmonth')
                    },
                datepicker:{                    
                    localData:{
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                        applyLabel:  this.$t('apply'),
                        cancelLabel:  this.$t('cancel'),
                        weekLabel: this.$t('w'),
                        customRangeLabel: this.$t('Customize'),
                        daysOfWeek: [this.$t('sun'), this.$t('mon'), this.$t('tue'), this.$t('wed'), this.$t('thu'), this.$t('fri'), this.$t('sat')],
                        monthNames: [this.$t('jan'), this.$t('feb'), this.$t('mar'), this.$t('apr'), this.$t('may'), this.$t('jun'), this.$t('jul'), this.$t('aug'), this.$t('sep'), this.$t('oct'), this.$t('nov'), this.$t('dec')],
                        firstDay: 0
                    },
                    ranges:{
                        'Today': [day, day],
                        'Yesterday': [yesterday, yesterday],
                        'This month': [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth(), 30)],
                        'This year': [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
                        'Last month': [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
                        
                    },
                },
				selectedDestinationId:'',
				
				
            }
        },

        mounted(){
            this.getFilters();
            this.getDestinations();
        },

        methods: {
            onRowSelected(items) {
                console.log(items)
                // this.selected = items
            },           
            getDestinations() {
				let that = this;
				// that.loadingData = true;
				
				let url   = '/webhook/destinations';
				let method= 'POST';
				let data  = {searchQuery:that.tableSearch.searchQuery/* , types:that.tableSearch.selectedFilters, dateRange:that.dateRange */};
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
                    that.loadingData = true;
                    this.items       = response.data.destinations;
                    this.Filters     = response.data.types;
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn');
					that.loadingData 	 = false;
				})	
			},
            getFilters() {
				let that = this;
				// that.loadingData = true;
				
				let url   = '/webhook/getFilters';
				let method= 'GET';
				let data  = {};
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.loadingData 	 = false;
                    this.types    = response.data.types;

                    this.optionsSelectFilter.defaultSelected = '';
                    this.optionsSelectFilter.defaultSelected = 'All';

				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn');
					that.loadingData 	 = false;
				})	
			},
            rangesfunc(ranges){
                var keyTranslations = this.transranges;
                var data = [];
                for (const [key, value] of Object.entries(ranges)) {
                    if(Object.keys(keyTranslations).includes(key)){
                        data[keyTranslations[key]] = value
                    }
                }
                return {...data}
			},
            searchTimeOut() {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.getDestinations(true, 'filter');
                }, 500);
            },
            InitDateRange () {
				let that = this
				that.dateRange = {}
				that.getDestinations(true, 'filter')
			},
            selectRow() {
                // console.log('im here')
                // if(this.selected.length == this.items.length){
                //     this.allSelected = true
                // }else{
                //     this.allSelected = false
                // }
            },
			/*deleteEvent(id){
				let that = this;
				that.$bvModal.show('modal-delete-destination');
				that.selectedDestinationId = id
			},
            deleteDestination(){
                let that = this;
				
				// that.loadingData = true;
				console.log('Aziz :' + that.selectedDestinationId)
				var id = that.selectedDestinationId
                let data = {id: id}
                that.idestination = data
                // let data = this.idestination
                // console.log(that.$store); return;
				
				let url    = '/webhook/destination/remove'
				let method = 'POST'

				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.loadingData 	 = false;
                    that.getDestinations();
                    that.notifyMe(response.data.message, 'success');
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingData 	 = false
				})
				that.$bvModal.hide('modal-delete-destination');
            },*/
            selectAllRows() {
                // if(this.allSelected) {
                //     this.$refs.selectableTable.selectAllRows()
                // }else{
                //     this.$refs.selectableTable.clearSelected()
                // }
            },
            clearSelected() {
                // this.$refs.selectableTable.clearSelected()
            },
            eventCallBack(type,id) {
				let that = this
				console.log('type :' + type)
				if(type == "sendinblue"){
					that.$router.push({path: 'destination/edit-sendinblue/'+id})
				}else if(type == "mailchimp"){
					that.$router.push({path: 'destination/edit-mailchimp/'+id})
				}else if(type == "mailjet"){
					that.$router.push({path: 'destination/edit-mailjet/'+id})
				}else if(type == "webhook"){
					that.$router.push({path: 'destination/edit/'+id})
				}
				
			}
        }
    }
</script>

<style lang="scss">
    .oml-destinationsListing{
        padding:17px;
		
        .oml-destsListing-header{
            .oml-destsListing-filter{
                display:flex;
                align-items:center;
                justify-content:flex-end;

                .form-group{
                    margin-bottom:0px;

                    &.input-searchZone-md{
						width:170px;
                        i{
                            position: absolute;
                            left: 8px;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 13px;
                            font-weight: 400;
                            font-size: 13px;
                            color: #dadfe7;
                        }

                        input{
                            padding-left:24px;
							color:#999;
							font-size:12px;
							
							
							&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
								font-size: 12px;
								color:#999;
							}
							
							&:-ms-input-placeholder { /* Internet Explorer 10-11 */
								font-size: 12px;
								color:#999;
							}
							
							&::-ms-input-placeholder { /* Microsoft Edge */
								font-size: 12px;
								color:#999;
							}
                        }
                    }
                }

                button{
                    border-radius:4px;
                    padding:8px 20px;
					height:40px;
                    color:#fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color:#1fbbf6;
                    border:1px solid #1fbbf6;

                    span{
                        font-size: 24px;
                        color: #fff;
                        margin-right: 10px;
                        line-height: 20px;
                    }
					
					&:hover{
						background-color:#01a3d6;
					}
                }

                .oml-desti-dateRangePicker{
                    .daterangepicker{
                        .drp-buttons{
                            display:flex;
                            align-items:center;
                            justify-content:flex-end;
							
							button{
								margin-left: 8px;
								font-size: 12px;
								font-weight: 700;
								padding: 4px 8px;
								border-radius:4px;
								
								&.cancelBtn{
									color:#212529;
									background-color:transparent;
									border:0px solid transparent;
								}
							}
                        }

                        &.openscenter:after, &.openscenter:before{
                            left:0 !important;
                        }
                    }
                }
            }
        }

        .oml-destinationsListing-body{
            .table{
                th{
                    padding-left:20px;
                    padding-right:20px;

                    div{
                        color:#b3b3b3 !important;
                        font-size:14px;
                        font-weight:400;
                    }
                }

                td{
                    font-size:13px;
                    padding-left:20px;
                    padding-right:20px;
                    vertical-align:middle;

                    .btn-group{
                        button{
                            padding:0;
                            line-height:16px;

                            i{
                                padding:0;
                            }
                        }
                    }

                    .margin-auto{
                        margin:0 auto;
                    }

                    .fw-700{
                        font-weight:700;
                    }

                    .oml-destStatus{
                        border-radius:4px;
                        color:#fff;
                        padding:1px 2px;
                        font-size:13px;
                        margin-bottom: 2px;
                        font-weight:400;
                        display:inline-block;

                        &.qualified{
                            background-color:#4caf50;
                        }

                        &.call-back{
                            background-color:#800080;
                        }
                    }
                }
            }

            .oml-destinationsListing-empty{
                padding:50px 0;
                text-align:center;
                display:flex;
                align-items:center;
                justify-content:center;

                h5{
                    font-weight: 700;
                    color: #1d2841;
                    font-size:20px;
                }

                p{
                    font-weight: 400;
                    color: #1d2841;
                    font-size:14px;
                    max-width:300px;
                }

                button{
                    min-width:250px;
                    border-radius:8px;
                    padding:8px 15px;
                    color:#fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span{
                        font-size: 24px;
                        color: #fff;
                        margin-right: 10px;
                        line-height: 20px;
                    }

                }
            }
        }
    }
</style>